<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 设置
 * @Date: 2020-12-25 17:11:52
 * @LastEditors: OBKoro1
 * @LastEditTime: 2020-12-30 21:40:08
 * @FilePath: /yue_quanzhan_h5_new/src/views/user/setup.vue
-->
<template>
  <div class="user_content">
    <van-cell-group class="waller_list">
      <van-cell title="关于我们" is-link url="https://www.yuetao.vip/mobile/about.html" />
      <van-cell title="服务协议" is-link to="/serviceIndex" />
      <van-cell title="隐私协议" is-link to="/Policy" />
      <van-cell title="退出" is-link @click="Logout" />
    </van-cell-group>
  </div>
</template>

<script>
import Vue from 'vue'
import { Cell, CellGroup, Toast } from 'vant'

Vue.use(Cell).use(CellGroup).use(Toast)
export default {
  data() {
    return {}
  },
  methods: {
    Logout() {
      this.$store.dispatch('user/logout', null).then(res => {
        Toast('退出成功')
        this.$router.push('/')
      })
    }
  }
}
</script>

<style lang="less" scoped>
.user_content {
  width: 100%;
  height: auto;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f6f6f6;

  .van-cell__title {
    text-align: left;
  }
}
</style>
